import {
  Dispatch, SetStateAction, createContext, useContext,
} from 'react';

import { Vertical } from '../../types/common';
import { HomeVerticalContextProps } from '@/components/home/_verticals/types/home';

type ContextState = {
  verticalHome: HomeVerticalContextProps;
  setVerticalHome: Dispatch<SetStateAction<any>>;
};

const initialState: ContextState = {
  verticalHome: {
    lastSeenAdIds: [],
    vertical: Vertical.Generalist,
    heroData: [],
    shopData: { shops: [] },
  },
  setVerticalHome: (): void => {},
};

const VerticalHomeContext = createContext(initialState);

export const useVerticalHomeContext = (): ContextState => useContext(VerticalHomeContext);

export default VerticalHomeContext;
