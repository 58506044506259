import { TilesData } from '@/components/home/Tiles/types';

const tilesData: TilesData[] = [
  {
    key: '1',
    title: 'Ingyenes szállítás\n több ezer termékre',
    background: '/assets/home/tiles/szallitas.png',
  },
  {
    key: '2',
    title: 'Naponta közel\n 25 000 új hirdetés',
    background: '/assets/home/tiles/hirdetes.png',
  },
  {
    key: '3',
    title: 'Nagy múltú platform,\n hazai háttérrel',
    background: '/assets/home/tiles/hazai.png',
  },
];

export default tilesData;
