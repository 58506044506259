import { adsBaseUrl, apiKey } from '@/config';
import apiFetch from '@/helpers/apiFetch';
import measurableFetch from '@/helpers/measurableFetch';

export const fetchAds = async ({ query }) => {
  const url = new URL(`${adsBaseUrl}/index`);
  Object.keys(query).forEach((key) => url.searchParams.append(key, query[key]));

  return measurableFetch(url, {
    method: 'GET',
    headers: {
      api_key: apiKey,
    },
  })
    .then((res) => res?.json())
    .then((res) => (res?.ads ? res.ads : []));
};

export const getPhone = async ({ listId }) => {
  const url = new URL(`${adsBaseUrl}/getPhone`);
  url.searchParams.append('list_id', listId);
  const res = await apiFetch('GET', url, null, null);
  return res?.json();
};

export const getEmail = async ({ token, listId }) => {
  const url = new URL(`${adsBaseUrl}/getEmail`);
  url.searchParams.append('list_id', listId);
  const res = await apiFetch('GET', url, null, token);
  return res.json();
};

export const getContactLocation = async ({ token, listId }) => {
  const url = new URL(`${adsBaseUrl}/getAddress`);
  url.searchParams.append('list_id', listId);
  const res = await apiFetch('GET', url, null, token);
  return res?.json();
};
