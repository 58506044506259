import {
  Box, Card, CardContent, Link, Typography,
} from '@mui/material';

import CardImage from './CardImage';
import JobDetails from './JobDetails';
import { AdCardProps } from './types';
import getNoPriceLabel from '@/components/adView/_selector/getNoPriceLabel';
import { customPriceFormatter } from '@/helpers/formatters';
import transformFriendlyUrlToDev from '@/helpers/product/transformFriendlyUrlToDev';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    regular: true;
  }
}

const AdCard: React.FunctionComponent<AdCardProps> = (
  {
    product, itemIndex, clickTrackingEvent, noBorder, trackingParams, layoutType, showLocation,
  },
) => {
  const millionPriceString = (product.price / 1000000).toString();
  const formattedPrice = product.price >= 1000000
    ? `${customPriceFormatter(Number.parseFloat(millionPriceString).toFixed(1))} M`
    : customPriceFormatter(product.price);

  const noPriceLabel = getNoPriceLabel(product.parameters);

  const rankedProduct = {
    ...product,
    rank: (itemIndex !== undefined ? itemIndex + 1 : 0),
  };

  const displayPrice: React.FunctionComponent<void> = () => {
    const suffix = product.salary_interval
      ? (
        <>
          &nbsp;Ft/
          {product.salary_interval}
        </>
      )
      : <>&nbsp;Ft</>;

    if (parseInt(formattedPrice, 10) > 0) {
      return (
        <Typography variant="h5">
          {layoutType === 'job' && (
            <span style={{ fontWeight: 'normal', fontSize: '0.875rem' }}>
              Br.
              {' '}
            </span>
          )}
          {formattedPrice}
          <span style={{ fontWeight: 'normal', fontSize: '0.875rem' }}>
            {' '}
            {suffix}
          </span>
        </Typography>
      );
    }
    return <Typography variant="regular" sx={{ lineHeight: '20px' }}>{noPriceLabel}</Typography>;
  };

  return (
    <Card
      sx={{
        boxShadow: noBorder ? 'none' : '1px 1px 3px 0 rgba(0, 0, 0, 0.12), 1px 1px 1px 0 rgba(0, 0, 0, 0.14), 1px 2px 1px -1px rgba(0, 0, 0, 0.2)',
        padding: noBorder ? 0 : 2,
        cursor: 'pointer',
        minHeight: '100%',
        border: '1px solid transparent',
      }}
    >
      <Link
        data-testid="ad-card-link"
        href={`${transformFriendlyUrlToDev({ link: product.friendly_url })}${trackingParams || ''}`}
        onClick={() => clickTrackingEvent?.(rankedProduct)}
        color="text.primary"
        sx={{
          '&:hover': { textDecoration: 'none' },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {
          layoutType !== 'job'
          && <CardImage product={product} noBorder={noBorder} />
        }
        <CardContent
          sx={{
            px: 0,
            '&:last-child': { pb: 0 },
            pt: layoutType !== 'job' ? 2 : 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: '1',
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
              overflow: 'hidden',
              mb: 1,
              height: '36px',
            }}
          >
            {product.title}
          </Typography>
          {
            layoutType === 'job'
            && <JobDetails product={product} />
          }
          <Box
            sx={{ display: 'flex' }}
            data-testid="suggested-ad-price-label"
          >
            {displayPrice()}
          </Box>
          {
            showLocation
            && (
              <Typography variant="caption" color="text.secondary" mt={1}>{product.location}</Typography>
            )
          }
        </CardContent>
      </Link>
    </Card>
  );
};

export default AdCard;
