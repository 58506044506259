import React from 'react';

import { Button, ButtonProps } from '@mui/material';

interface ModalButtonProps extends ButtonProps {
  children: React.ReactNode;
}

const ModalButton = React.forwardRef<HTMLButtonElement, ModalButtonProps>((props, ref) => {
  const { children } = props;

  return (
    <Button
      {...props}
      ref={ref}
      sx={{ width: { xs: '100%', sm: 'auto' } }}
    >
      {children}
    </Button>
  );
});

export default ModalButton;
