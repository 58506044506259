import { isDev } from '@/config';

const transformFriendlyUrlToDev = ({ link }: { link: string }):string => {
  if (isDev) {
    return link?.replace(/https:\/\/(www\.)?((auto|allas|ingatlan).)?test-jofogas\.hu/g, '') || '';
  }
  return link;
};

export default transformFriendlyUrlToDev;
