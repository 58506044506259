import { CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { defaultAdPic } from '@/config';

type CardImageProps = {
  product: {
    image_url?: string;
    title: string;
  },
  noBorder?: boolean;
};

const CardImage: React.FC<CardImageProps> = ({ product, noBorder }) => {
  const theme = useTheme();
  const placeHolderImgStyles = {
    backgroundSize: '80px',
    backgroundColor: theme.palette.background.placeholderBackground,
  };

  return (
    <CardMedia
      data-hj-suppress
      sx={{
        ...(!product.image_url && placeHolderImgStyles),
        aspectRatio: '4/3',
        objectFit: product.image_url ? 'cover' : 'scale-down',
        borderRadius: '4px',
        maxHeight: noBorder ? '192px' : 'auto',
      }}
      component="img"
      loading="lazy"
      image={product.image_url ? product.image_url : defaultAdPic}
      title={product.title}
    />
  );
};

export default CardImage;
