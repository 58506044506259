import React from 'react';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

type Props = {
  children: React.ReactNode;
  componentName: string;
};

const ComponentErrorBoundary = ({ children, componentName }: Props): React.ReactNode => (
  <ErrorBoundary componentName={componentName}>
    {children}
  </ErrorBoundary>
);

export default ComponentErrorBoundary;
