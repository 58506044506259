import React, { useEffect, useState } from 'react';

import {
  Card, Container, Grid, Typography, Box, Button, CardContent,
} from '@mui/material';

import { InfoBannerProps } from './types';
import AdInsertionButton from '@/components/common/AdInsertion/Button';
import { useUserContext } from '@/contexts/User/UserContext';
import useViewportType from '@/hooks/useViewportType';
import useWindowSize from '@/hooks/useWindowSize';

const InfoBanner: React.FunctionComponent<InfoBannerProps> = (
  { ButtonElement, iconUrl, children = null },
) => (
  <Card
    sx={{
      position: 'relative',
      backgroundImage: `url(${iconUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom right',
      backgroundSize: 'contain',
      height: '100%',
    }}
  >
    <CardContent
      sx={{
        p: { xs: 2, md: 4 },
        '&:last-child': { pb: { xs: 2, md: 4 } },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box pb={{ xs: 2, md: 4 }} width={{ xs: '66%', md: '100%' }}>
        {children}
      </Box>
      <Box>{ButtonElement}</Box>
    </CardContent>
  </Card>
);

const ActionCards: React.FunctionComponent = () => {
  const { userState } = useUserContext();
  const { account } = userState;
  const size = useWindowSize();
  const viewportType = useViewportType(size);
  const [buttonSize, setButtonSize] = useState<'medium' | 'large'>('medium');

  useEffect(() => {
    if (viewportType === 'mobile') {
      setButtonSize('medium');
    }
    if (viewportType === 'desktop') {
      setButtonSize('large');
    }
  }, [viewportType]);

  return (
    <Container>
      <Grid container spacing={4} mb={4} sx={{ position: 'relative', zIndex: 2 }}>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <InfoBanner
            ButtonElement={(
              <AdInsertionButton
                id="ob-adinsert"
                text="Feladok egy hirdetést"
                size={buttonSize}
                account={account}
                channel=""
                data-testid="home-ad-insert-button"
              />
            )}
            iconUrl="/assets/home/action-cards/hirdetes_feladas_kep_2x.png"
          >
            <Typography variant="body1">
              Adj fel hirdetést&nbsp;
              <b>könnyen és gyorsan!</b>
            </Typography>
          </InfoBanner>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoBanner
            ButtonElement={(
              <Button
                id="ob-partners"
                size={buttonSize}
                color="secondary"
                href="https://ajanlataink.jofogas.hu/"
                data-testid="home-business-package-button"
              >
                Üzleti csomagok
              </Button>
            )}
            iconUrl="/assets/home/action-cards/uzleti_csomagok_kep_2x.png"
          >
            <Typography variant="body1">
              <b>Hozz ki többet vállalkozásodból&nbsp;</b>
              a segítségünkkel!
            </Typography>
          </InfoBanner>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActionCards;
