export const numberFormatter = (number) => Intl.NumberFormat('hu-HU').format(number);

export const priceFormatter = (price) => {
  if (price === undefined) return ' ';

  return Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: 'HUF',
    maximumFractionDigits: 0,
  }).format(price);
};

/*
  This is a hotfix for price formatting until we update Node.js because of an error between
  the client and Node.js way of handling Intl.NumberFormat.
*/
export const customPriceFormatter = (price) => {
  const numStr = parseInt(price, 10)?.toFixed(0).toString() || '0';
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const dateFormatter = (date, options = {}) => {
  const optionsObj = {
    dateStyle: options.dateStyle || 'medium', // full, long, medium, short
    timeZone: 'CET',
  };

  if (!options.timeStyle || options.timeStyle !== 'none') {
    optionsObj.timeStyle = options.timeStyle || 'short'; // full, long, medium, short)
  }

  return Intl.DateTimeFormat('hu-HU', optionsObj).format(date);
};

export const formatMessageDate = ({
  date, isToday, isYesterday, translator, format,
}) => {
  let displayedDate;

  if (isToday) {
    displayedDate = `${translator('info.today')}, ${format('hh:mm', date)}`;
  } else if (isYesterday) {
    displayedDate = `${translator('info.yesterday')}, ${format('hh:mm', date)}`;
  } else {
    displayedDate = format('yyyy.MM.dd hh:mm', date);
  }

  return displayedDate;
};

export const formatLastSeenValue = (lastSeen = null) => {
  const now = new Date().getTime() / 1000;
  if (!lastSeen || new Date(lastSeen).toString() === 'Invalid Date') {
    return false;
  }

  const hours = Math.round((now - (typeof lastSeen === 'number' ? lastSeen : new Date(lastSeen).getTime() / 1000)) / 60 / 60) || 1;

  if (hours < 24) {
    return `${hours} órája`;
  }

  const days = Math.round(hours / 24);

  if (days > 90) {
    return false;
  }

  return `${days} napja`;
};

export const formatPhoneNumber = (phone) => phone
  .replace(/\D/g, '')
  .replace(
    /^(\+?3|0)6(1|\d{2})(\d{3})(\d{3}|\d{4})$/,
    '+36 $2 $3 $4',
  );
