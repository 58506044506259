import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { Stack, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Details = {
  product: {
    categoryName?: string,
    jobType?: string,
  }
};

const JobDetails: React.FunctionComponent<Details> = ({ product }) => {
  const theme = useTheme();
  const iconStyle = {
    width: '20px',
    height: '20px',
    color: theme.palette.text.secondary,
    mr: 2,
  };

  const jobDetailsStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <Stack>
      <Box display="flex" mb={1}>
        <BadgeOutlinedIcon sx={iconStyle} />
        <Typography variant="body2" color="text.secondary" sx={jobDetailsStyle}>
          {product.categoryName}
        </Typography>
      </Box>
      <Box display="flex" mb={1}>
        <AccessTimeOutlinedIcon sx={iconStyle} />
        <Typography variant="body2" color="text.secondary" sx={jobDetailsStyle}>
          {product.jobType}
        </Typography>
      </Box>
    </Stack>
  );
};

export default JobDetails;
