import {
  DialogContent, Grid, Link, Typography,
} from '@mui/material';

import Modal from '@/components/common/Modal';
import ModalButton from '@/components/common/ModalButton';
import { useModalContext } from '@/contexts/Modal/ModalContext';

const InfoModal = () => {
  const { hideModal } = useModalContext();

  return (
    <Modal variant="info" title="Promóciós célú hirdetések">
      <DialogContent dividers>
        <Grid container direction="column" mt={2} rowGap={6}>
          <Typography>
            Itt olyan hirdetéseket láthatsz, amiket promóciós céllal jelenítünk meg. Ezek lehetnek
            valamilyen, az eladó által vásárolt kiemelést tartalmazó, vagy a Jófogáson eddig
            megtekintett tartalmaid alapján ajánlott hirdetések.
          </Typography>
          <Typography>
            Bővebben a kiemelésekről és a rendezési elvekről
            {' '}
            <Link href="https://docs.jofogas.hu/tajekoztato-rangsorolasrol" target="_blank">ITT</Link>
            {' '}
            olvashatsz.
          </Typography>
          <Grid item>
            <Grid container justifyContent="center">
              <ModalButton color="info" onClick={hideModal}>Értem</ModalButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default InfoModal;
