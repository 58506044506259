import React from 'react';

import {
  Container, Box, Grid,
} from '@mui/material';

import { FreshAdsProps } from './types';
import Headline from '@/components/adView/common/Headline';
import { Product } from '@/components/adView/types';
import AdCard from '@/components/common/AdCard';
import getAdCardProduct from '@/components/common/AdCard/helpers/getAdCardProduct';
import MobileView from '@/components/common/Device/MobileView';
import InfoModal from '@/components/landing/Modals/PromotionInfoModal';
import { useVerticalHomeContext } from '@/contexts/VerticalHome/VerticalHomeContext';
import useViewportType from '@/hooks/useViewportType';
import useWindowSize from '@/hooks/useWindowSize';

const FreshAds = ({ freshAds, showLocation }: FreshAdsProps): JSX.Element | null => {
  const size = useWindowSize();
  const actualViewportType = useViewportType(size);
  const { verticalHome } = useVerticalHomeContext();

  if (!freshAds && !Array.isArray(freshAds)) {
    return null;
  }

  return (
    <Container disableGutters>
      {freshAds.map((category: any) => {
        if (category.ads.length === category.limit) {
          return (
            <Box
              key={category.categoryId}
              sx={{
                position: 'relative',
                ':not(:last-child)': { marginBottom: 4 },
              }}
            >
              <Box mb={1} zIndex={5} position="relative">
                <Headline
                  position="fresh_ads"
                  readMoreData={
                    actualViewportType === 'desktop' ? category.readMoreData : undefined
                  }
                  // @ts-expect-error todo
                  infoModalComponent={InfoModal}
                >
                  {category.title}
                </Headline>
              </Box>
              <Box mb={{ xs: 2, sm: 0 }}>
                <Grid container spacing={{ xs: 2, md: 4 }} zIndex={4}>
                  {category.ads.map((product: Product, i: number) => (
                    <Grid key={product.list_id} item xs={6} sm={3} zIndex={4}>
                      <AdCard
                        layoutType={verticalHome.vertical === 'job' ? 'job' : undefined}
                        product={getAdCardProduct(product)}
                        itemIndex={i}
                        trackingParams="#pos=fresh_ads&opt=homepage"
                        showLocation={showLocation}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <MobileView>
                <Headline readMoreData={category.readMoreData} />
              </MobileView>
            </Box>
          );
        }
        return null;
      })}
    </Container>
  );
};
export default FreshAds;
