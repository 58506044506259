import {
  StorefrontOutlined, HomeWorkOutlined, DirectionsCarOutlined, WorkOutlineOutlined,
} from '@mui/icons-material';

import {
  homeUrl, realEstateHomeUrl, carHomeUrl, jobHomeUrl,
} from '@/config';

const bluebarLinksData = [
  {
    id: 'main',
    text: 'Piactér',
    url: homeUrl,
    icon: StorefrontOutlined,
  },
  {
    id: 'ingatlan',
    text: 'Ingatlan',
    url: realEstateHomeUrl,
    icon: HomeWorkOutlined,
  },
  {
    id: 'car',
    text: 'Jármű',
    url: carHomeUrl,
    icon: DirectionsCarOutlined,
  },
  {
    id: 'job',
    text: 'Állás',
    url: jobHomeUrl,
    icon: WorkOutlineOutlined,
  },
];

export default bluebarLinksData;
