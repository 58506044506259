import React from 'react';

const HeroBackgroundSvg = (): JSX.Element => (
  <svg
    style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
    }}
    width="449"
    height="auto"
    viewBox="0 0 449 302"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity=".8" clipPath="url(#vn6v533v3a)">
      <path
        d="M-16.434 342.789c-36.18-83.086-23.467-211.788 80.111-230.358 1.434-.278 2.907-.49 4.38-.684 1.469-.202 2.945-.407 4.453-.526.159-.074.439-.105.633-.103 28.213-3.094 56.588 2.914 84.578 10.179 5.122 1.309 10.233 2.613 15.311 4.043 13.583 3.481 27.004 7.047 40.249 9.609 13.172 2.605 27.33 3.568 41.658 4.266 1.619.116 3.208.153 4.827.268 1.618.116 3.208.153 4.829.275 18.642.913 37.358 1.983 54.816 5.801 7.13 1.628 14.052 3.656 20.637 6.416.978.416 1.959.84 2.936 1.256 42.141 18.867 60.577 72.313 50.818 117.41-.493 2.084-.976 4.172-1.469 6.255-.834 3.221-1.832 6.325-2.978 9.507-2.065 6.24-4.678 12.35-7.524 18.376l-.083.039c-.251.505-.422.982-.673 1.486a173.671 173.671 0 0 1-9.08 15.69c-.997 1.638-2.069 3.312-3.216 4.827a175.299 175.299 0 0 1-12.6 16.753c-3.728 4.579-7.724 8.998-11.826 13.172-.084.233-.286.429-.492.619a50.318 50.318 0 0 1-2.889 3.015c-1.138 1.123-2.322 2.362-3.491 3.399-3.99 4.02-8.169 7.835-12.384 11.574a467.201 467.201 0 0 1-8.514 7.315c-22.4 18.626-47.436 33.893-73.601 45.11-4.966 2.138-9.895 4.158-14.938 5.936-1.003.37-1.996.744-3.035 1.038-21.478 7.644-43.509 12.424-65.227 13.932-1.469.202-3.02.248-4.528.367-1.508.119-2.976.126-4.481.252a181.22 181.22 0 0 1-13.152-.072c-18.966-.566-37.508-3.773-54.982-9.915-3.027-1.021-6.045-2.238-8.981-3.495-29.956-12.489-56.415-34.24-76.343-66.669-1.902-3.014-3.682-6.177-5.425-9.459-2.991-5.331-5.743-10.977-8.325-16.887v-.017z"
        fill="#fff"
        fillOpacity=".86"
      />
    </g>
    <defs>
      <clipPath id="vn6v533v3a">
        <path fill="#fff" transform="rotate(154.847 187.4 215.732)" d="M0 0h426v366H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default HeroBackgroundSvg;
