import useViewportType from '@/hooks/useViewportType';
import useWindowSize from '@/hooks/useWindowSize';

const ViewportCheckDisplayWrapper = ({ children, viewportType }) => {
  const size = useWindowSize();
  const actualViewportType = useViewportType(size);

  if (actualViewportType !== viewportType) {
    return null;
  }

  return children;
};

export default ViewportCheckDisplayWrapper;
