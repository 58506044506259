import { VerticalList } from '@/types/common';

const getLastSeenAdIds = (accountId: string, vertical?: VerticalList): number[] => {
  if (window?.localStorage && vertical) {
    return JSON.parse(window.localStorage?.getItem('lastSeenAds') || '{}')?.[accountId]?.[vertical] || [];
  }

  return [];
};

export default getLastSeenAdIds;
