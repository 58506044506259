import React from 'react';

import {
  Box,
  Button,
  SvgIcon,
  Divider,
  Container,
  Grid,
} from '@mui/material';

import bluebarLinksData from './bluebarLinksData';

// TOOD: hozzáadni a location searchet
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    buttonMedium: true;
  }
}

type MenuPoints = {
  id: string,
  text: string,
  url: string,
  icon: any,
};

const BluebarVerticalLinks: React.FunctionComponent<{
  active?: 'main' | 'ingatlan' | 'car' | 'job',
}> = ({
  active = 'main',
}) => {
  const activeLink = bluebarLinksData.findIndex((link: MenuPoints) => link.id === active);

  return (
    <Grid container sx={{ bgcolor: 'background.blueBar' }}>
      <Container>
        <Box
          id="ob-verticals"
          sx={{
            display: 'inline-flex', flexDirection: 'row', alignItems: 'center', py: '6px',
          }}
        >
          {
        bluebarLinksData.map((link, index) => (
          <React.Fragment key={link.text}>
            <Button
              id={link.id}
              href={`${link.url}?pos=blue_bar&opt=homepage`}
              variant="text"
              className={`${activeLink === index && 'blue-bar-active-item'}`}
              sx={{
                flex: { xs: 'auto', md: 'initial' },
                minWidth: 'auto',
                color: activeLink === index
                  ? 'primary.main'
                  : 'secondary.main',
                minHeight: '100%',
                '&:not(.blue-bar-active-item):hover': {
                  backgroundColor: 'action.hover',
                  color: 'secondary.dark',
                },
                '&.blue-bar-active-item:hover': {
                  backgroundColor: 'transparent',
                },
                '& .MuiButton-startIcon': {
                  minWidth: '0',
                  margin: { xs: '0px', md: '0px 8px 0px -4px' },
                },
              }}
              role="navigation"
              startIcon={<SvgIcon component={link.icon} sx={{ maxHeight: '24px' }} />}
            >
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {link.text}
              </Box>
            </Button>
            {index + 1 !== bluebarLinksData.length
              && (
                <Divider
                  orientation="vertical"
                  key={`divider-${link.text}`}
                  sx={{
                    height: '16px',
                    mx: 4,
                    borderColor: 'other.outlinedBorder',
                  }}
                />
              )}
          </React.Fragment>
        ))
      }
        </Box>
      </Container>
    </Grid>
  );
};

export default BluebarVerticalLinks;
