import { useMemo, useState, useEffect } from 'react';

import { useUserContext } from '../User/UserContext';
import { HomeVerticalContextProps } from '@/components/home/_verticals/types/home';
import VerticalHomeContext from '@/contexts/VerticalHome/VerticalHomeContext';
import getLastSeenAdIds from '@/helpers/product/getLastSeenAdIds';

type Props = {
  verticalHome?: any,
  children: React.ReactNode
};

const VerticalHomeContextWrapper = ({
  children,
  verticalHome: iVerticalHome = {},
}: Props): React.ReactNode => {
  const [verticalHome, setVerticalHome] = useState<HomeVerticalContextProps>(iVerticalHome);
  const [lastSeenAdIds, setLastSeenAdIds] = useState<number[]>([]);

  const { userState } = useUserContext();
  const { account } = userState;

  const { vertical } = verticalHome;

  const initVerticalHomeContext = useMemo(() => ({
    setVerticalHome,
    verticalHome: {
      ...verticalHome,
      lastSeenAdIds,
    },
  }), [
    verticalHome,
    lastSeenAdIds,
  ]);

  useEffect(() => {
    setVerticalHome(iVerticalHome);
  }, [iVerticalHome]);

  useEffect(() => {
    if (account?.account_id) {
      setLastSeenAdIds(getLastSeenAdIds(account.account_id, vertical));
    }
  }, [account?.account_id, vertical]);

  return (
    <VerticalHomeContext.Provider value={initVerticalHomeContext}>
      {children}
    </VerticalHomeContext.Provider>
  );
};

export default VerticalHomeContextWrapper;
