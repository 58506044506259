import { ArrowForward } from '@mui/icons-material';
import {
  Stack, Button, Typography, Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { HeroContentProps } from '@/components/home/Hero/types';
import { clickHeroCTA } from '@/helpers/tracking/homepage';

const HeroContent = ({ content, position }: HeroContentProps): JSX.Element => {
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    clickHeroCTA(`Position ${position}`);
    const { url } = content.cta;
    window.open(url, url.includes('jofogas.hu') ? '_self' : '_blank');
  };

  return (
    <Stack
      pt={4}
      height="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
      spacing={2}
    >
      <Typography variant="h2" color="textPrimary">{content.title}</Typography>
      {content.description && (
        <Box
          sx={{
            width: { xs: 'auto', sm: '40ch' },
            flex: 1,
          }}
        >
          <Typography
            variant="body1"
            color="textPrimary"
          >
            {content.description}
          </Typography>
        </Box>
      )}

      {content.cta && (
        <Button
          onClick={(e) => handleClick(e)}
          variant="contained"
          endIcon={<ArrowForward fontSize="small" />}
          size="medium"
          sx={{
            marginTop: 'auto',
            alignSelf: { xs: 'stretch', sm: 'flex-start' },
            backgroundColor: 'white',
            color: theme.palette.secondary.main,
            border: `solid 1px ${theme.palette.secondary.main}`,
            ':hover': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
            },
          }}
        >
          {content.cta.text}
        </Button>
      )}
    </Stack>
  );
};

export default HeroContent;
