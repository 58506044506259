import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Dialog, DialogTitle, IconButton, Typography,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';

import { ModalProps } from './types';
import { useModalContext } from '@/contexts/Modal/ModalContext';

const Transition = React.forwardRef((props, ref) => (
  // @ts-expect-error todo
  <Slide direction="up" ref={ref} {...props} />
));

const Modal = ({
  size, id, title, variant = 'info', children,
}: ModalProps): JSX.Element => {
  const { hideModal } = useModalContext();
  const theme = useTheme();

  return (
    <Dialog
      open
      fullWidth
      maxWidth={size}
      id={id}
      PaperProps={{
        sx: {
          maxWidth: { xs: '100%', sm: '480px', md: '640px' },
          width: '100%',
          margin: 0,
          position: { xs: 'fixed', sm: 'relative' },
          bottom: 0,
        },
      }}
      // @ts-expect-error todo
      TransitionComponent={Transition}
      onClose={hideModal}
    >
      {!!title && (
        <DialogTitle sx={{ textAlign: 'center', textTransform: 'uppercase', pb: 0 }}>
          <Box
            component="div"
            sx={{
              paddingBottom: 2,
              borderBottom: '3px solid',
              borderColor: theme.palette[variant].main,
              display: 'inline-block',
              maxWidth: '80%',
            }}
          >
            <Typography variant="h4" component="span">{title}</Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={hideModal}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: theme.palette.secondary.main,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      {children}
    </Dialog>
  );
};

export default Modal;
