import React from 'react';

import * as Sentry from '@sentry/nextjs';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  componentName?: string | null;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: { componentName: string | null; };

  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): any {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any): void {
    const { componentName } = this.props;

    if (componentName) {
      Sentry.setTag('componentName', componentName);
    }

    Sentry.captureException({ error, errorInfo });
  }

  render(): React.ReactNode {
    // Check if the error is thrown
    const { hasError } = this.state as any;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          Komponens betöltése sikertelen
        </div>
      );
    }

    // Return children components in case of no error

    return children;
  }
}

ErrorBoundary.defaultProps = {
  componentName: null,
};

export default ErrorBoundary;
