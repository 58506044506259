import { Grid } from '@mui/material';

import { VerticalBackgroundProp } from './types';

const VerticalBackground: React.FunctionComponent<VerticalBackgroundProp> = (
  { children, backgroundUrl },
) => (
  <Grid
    container
    className="vertical-background-wrapper"
    sx={{
      py: 6,
      backgroundColor: '#f6f6f6',
      backgroundImage: {
        xs: 'none',
        lg: `url(${backgroundUrl})`,
      },
      backgroundRepeat: 'repeat',
      '&:before': {
        zIndex: 2,
        background: 'linear-gradient(180deg, rgba(246,246,246,0.5) 0%, rgba(233,233,233,1) 100%)',
      },
      flexDirection: 'column',
    }}
  >
    {children}
  </Grid>
);
export default VerticalBackground;
